import { gql } from '@apollo/react-hooks'

export const GET_CHART_OF_ACCOUNT = gql`
  query GetChartOfAccounts($companyId: String!) {
    getChartOfAccounts(companyId: $companyId) {
      error
      chartOfAccounts {
        accountCodes {
          accountId
          accountName
          approvalMatrix
        }
      }
    }
  }
`

export const GET_PURCHASE_ORDER_LIST = gql`
  query getPurchaseOrderList($projectId: String!,$companyId:String!,$page:Int!,$filter: SubmissionDataFilters,$submissionSort:SubmissionSort) {
    getPurchaseOrderList(
      pagination: { perPage: ${10}, page: $page}
      purchaseOrderData: {
        projectId: $projectId
        companyId: $companyId
      }
      submissionFilter:$filter
      submissionSort:$submissionSort
    ) {
      error
      pageInfo {
        totalCount
        perPage
      }
      isApprover
      purchaseOrder {
        isAllowed
        vendorName
        vendorEmail
        approvalStatus {
          status
          id
          isFinal
          approvalUserId {
            userId {
              id
            }
          }
        }
        description
        messageCount {
          id
          userId
          count
        }
        quickbooks {
          id
          syncToken
        }
        updateCount
        qualifiedExpense
        inputFinance
        commitment
        comments
        sets
        referenceCount
        reviewerStatus {
          inputFinance
          reviewerUserId {
            userId {
              id
            }
          }
        }
        accountSubtotals {
          accountCode
          details
          subtotal
        }
        formName
        orderId
        createdAt
        id
        submissionStage
        submissionDate
        projectId {
          audit
        }
        createdBy {
          userId {
            firstName
            lastName
          }
        }
        audit
      }
    }
  }
`
export const GET_WIRE_TRANSFER_LIST = gql`
  query getWireTransferList($projectId: ID!,$companyId:ID!,$page:Int!,,$filter: SubmissionDataFilters,$submissionSort:SubmissionSort){
    getWireTransferList(
      pagination: { perPage: ${10}, page: $page }
      wireTransferData: {
        projectId: $projectId
        companyId: $companyId
      }
      submissionFilter:$filter
      submissionSort:$submissionSort
    ) {
      error
      pageInfo {
        totalCount
        perPage
      }
      isApprover
      wireTransferData {
        isAllowed
        vendorName
        vendorEmail
        approvalStatus {
          status
          id
          isFinal
          approvalUserId {
            userId {
              id
            }
          }
        }
        description
        messageCount {
          id
          userId
          count
        }
        quickbooks {
          id
          syncToken
        }
        apNumber
        amount
        inputFinance
        purchaseOrderNumber
        id
        formName
        submissionStage
        submissionDate
        updateCount
        qualifiedExpense
        paid
        paidDate
        comments
        invoiceNumber
        projectId {
          audit
        }
        reviewerStatus {
          inputFinance
          paid
          paidDate
          reviewerUserId {
            userId {
              id
            }
          }
        }
        sets
        createdAt
        accountSubtotals {
          accountCode
          details
          subtotal
        }
        purchaseOrderNumber
        dateRequired
        bankInfo {
          bankAccountNumber
          bankAddress
          bankName
          routingNumber
        }
        createdBy {
          userId {
            firstName
            lastName
          }
        }
        audit
      }
    }
  }
`

export const GET_CHECK_REQUEST_LIST = gql`
  query getCheckRequestList($projectId: ID!, $companyId: ID!,$page:Int!,$filter: SubmissionDataFilters,$submissionSort:SubmissionSort) {
    getCheckRequestList(
      checkRequestInput: {
        projectId: $projectId
        companyId: $companyId
      }
      submissionFilter:$filter
      pagination: { perPage: ${10}, page: $page }
      submissionSort:$submissionSort
    ) {
      error
      pageInfo {
        totalCount
        perPage
      }
      isApprover
      checkRequestData {
        isAllowed
        vendorName
        vendorEmail
        approvalStatus {
          status
          id
          isFinal
          approvalUserId {
            userId {
              id
            }
          }
        }
        description
        projectId {
          audit
        }
        messageCount {
          id
          userId
          count
        }
        quickbooks {
          id
          syncToken
        }
        apNumber
        inputFinance
        amount
        formName
        id
        submissionStage
        purchaseOrderNumber
        submissionDate
        accountSubtotals {
          accountCode
          details
          subtotal
        }
        qualifiedExpense
        createdAt
        reviewerStatus {
          inputFinance
          paid
          paidDate
          reviewerUserId {
            userId {
              id
            }
          }
        }
        updateCount
        comments
        invoiceNumber
        sets
        dateRequired
        createdBy {
          userId {
            firstName
            lastName
          }
        }
        audit
      }
    }
  }
`
export const GET_CREDIT_CARD_CHARGE = gql`
  query getCreditCardChargeList($projectId: String!, $companyId: String!,$page:Int!,$filter: SubmissionDataFilters,$submissionSort:SubmissionSort) {
    getCreditCardChargeList(
      pagination: { perPage: ${10}, page: $page }
      creditCardChargeInput: {
        projectId: $projectId
        companyId: $companyId
      }
      submissionFilter:$filter
      submissionSort:$submissionSort
    ) {
      error
      pageInfo {
        totalCount
        perPage
      }
      isApprover
      creditCardCharge {
        isAllowed
        vendorName
        approvalStatus {
          status
          id
          isFinal
          approvalUserId {
            userId {
              id
            }
          }
        }
        description
        projectId {
          audit
        }
        messageCount {
          id
          userId
          count
        }
        commitment
        inputFinance
        creditCardNumber
        formName
        id
        submissionStage
        updateCount
        qualifiedExpense
        reviewerStatus {
          inputFinance
          reviewerUserId {
            userId {
              id
            }
          }
        }
        comments
        createdAt
        accountSubtotals {
          accountCode
          details
          subtotal
        }
        sets
        orderId
        creditCardAccountName
        createdBy {
          userId {
            firstName
            lastName
          }
        }
        submissionDate
        audit
      }
    }
  }
`

export const GET_PURCHASE_DETAIL = gql`
  query getPurchaseOrderDetail(
    $purchaseOrderId: String!
    $page: Int!
    $page1: Int!
    $fulfillmentFilter: JSON
    $POFilter: JSON
  ) {
    getPurchaseOrderDetail(
      purchaseOrderId: $purchaseOrderId
      fulfillmentPageInput: { perPage: 10, page: $page }
      fulFillmentFilter: $fulfillmentFilter
      POStoryFilterInput: $POFilter
      POStoryPageInput: { perPage: 10, page: $page1 }
    ) {
      error
      fulfillmentsPageInfo {
        page
        perPage
        totalCount
      }
      POStoryPageInfo {
        page
        perPage
        totalCount
      }
      purchaseOrder {
        isAllowed
        audit
        formName
        orderId
        submissionDate
        vendorName
        vendorEmail
        description
        qualifiedExpense
        sets
        commitment
        updateCount
        inputFinance
        accountingSignOff
        comments
        additionalNotes
        episode
        w9Upload
        proofOfPayment
        id
        submissionStage
        contractUpload
        company {
          companyName
          companyAddress
          file
        }
        projectId {
          projectName
          exportType
          audit
        }
        createdBy {
          userId {
            firstName
            lastName
          }
        }
        approvalStatus {
          status
          isFinal
          id
          approvalUserId {
            role
            id
            userId {
              firstName
              lastName
              signatureUpload
              id
            }
          }
        }

        givenApproval {
          mainApproverId
        }
        accountSubtotals {
          accountCode
          details
          subtotal
        }
        purchaseOrderInfo {
          pickUpDate
          returnDate
          departureLocation
          arrivalLocation
          passengerName
          guestName
          anticipatedCheckoutDate
          anticipatedCheckInDate
          type
        }
      }
      fulfillments {
        amount
        createdAt
        description
        isDeleted
        status
        submissionId
        createdBy {
          userId {
            firstName
            lastName
          }
        }
      }
      submissionSignOff {
        signature
        lastName
        id
        firstName
        role
      }
      purchaseOrderStory {
        vendorName
        totalAmount
        submissionId
        remainingAmount
        referenceId
        POstatus
        chargeAmount
        createdAt
        createdBy {
          userId {
            firstName
            lastName
          }
        }
      }
    }
  }
`

export const GET_WIRE_TRANSFER_DETAIL = gql`
  query getWireTransferDetail(
    $wireTransferId: String!
    $page: Int!
    $filter: JSON
  ) {
    getWireTransferDetail(
      wireTransferId: $wireTransferId
      fulfillmentPageInput: { perPage: 10, page: $page }
      fulFillmentFilter: $filter
    ) {
      fulfillmentsPageInfo {
        page
        perPage
        totalCount
      }
      wireTransferData {
        projectId {
          projectName
          exportType
          audit
        }
        isAllowed
        audit
        formName
        submissionDate
        vendorName
        vendorEmail
        description
        qualifiedExpense
        company {
          companyName
          companyAddress
          file
        }
        accountSubtotals {
          accountCode
          details
          subtotal
          approvalMatrix
        }
        sets
        comments
        amount
        paid
        invoiceNumber
        accountName
        episode
        updateCount
        w9Upload
        apNumber
        dateRequired
        proofOfPayment
        paidDate
        invoice
        accountingSignOff
        accountName
        submissionStage
        contractUpload
        createdBy {
          userId {
            firstName
            lastName
          }
        }
        approvalStatus {
          status
          isFinal
          id
          approvalUserId {
            role
            id
            userId {
              firstName
              lastName
              signatureUpload
              id
            }
          }
        }
        givenApproval {
          mainApproverId
        }
        inputFinance
        additionalNotes
        purchaseOrderNumber
        bankInfo {
          bankAccountNumber
          bankAddress
          bankName
          currency
          routingNumber
        }
        wireTransferInfo {
          type
          locationRentalEnd
          locationRentalStart
          pickUpDate
          returnDate
        }
        dateRequired
        invoiceNumber
      }
      submissionSignOff {
        firstName
        lastName
        id
        role
        signature
      }
      fulfillments {
        amount
        createdAt
        description
        isDeleted
        status
        submissionId
        createdBy {
          userId {
            firstName
            lastName
          }
        }
      }
      error
    }
  }
`

export const GET_CREDIT_CARD_CHARGE_DETAIL = gql`
  query getCreditCardChargeDetail(
    $creditCardChargeId: String!
    $page: Int!
    $filter: JSON
  ) {
    getCreditCardChargeDetail(
      creditCardChargeId: $creditCardChargeId
      fulfillmentPageInput: { perPage: 10, page: $page }
      fulFillmentFilter: $filter
    ) {
      fulfillmentsPageInfo {
        page
        perPage
        totalCount
      }
      creditCardCharge {
        isAllowed
        audit
        formName
        orderId
        submissionDate
        vendorName
        description
        qualifiedExpense
        sets
        w9Upload
        proofOfPayment
        covidCost
        episode
        invoiceUpload
        commitment
        updateCount
        inputFinance
        comments
        additionalNotes
        creditCardAccountName
        accountingSignOff
        creditCardNumber
        submissionStage
        companyId {
          companyAddress
          companyName
          file
        }
        projectId {
          projectName
          exportType
          audit
        }
        accountSubtotals {
          accountCode
          details
          subtotal
          approvalMatrix
        }
        createdBy {
          userId {
            lastName
            firstName
          }
        }
        approvalStatus {
          status
          isFinal
          id
          approvalUserId {
            userId {
              firstName
              lastName
              signatureUpload
              id
            }
            role
            id
          }
        }
        givenApproval {
          mainApproverId
        }
        submissionInfo {
          anticipatedCheckInDate
          anticipatedCheckoutDate
          arrivalLocation
          departureLocation
          guestName
          passengerName
          pickUpDate
          returnDate
          type
        }
      }
      submissionSignOff {
        firstName
        lastName
        id
        role
        signature
      }
      fulfillments {
        amount
        createdAt
        description
        isDeleted
        status
        submissionId
        createdBy {
          userId {
            firstName
            lastName
          }
        }
      }
      error
    }
  }
`

export const GET_CHECK_REQUEST_DETAIL = gql`
  query getCheckRequestDetail(
    $checkRequestId: String!
    $page: Int!
    $filter: JSON
  ) {
    getCheckRequestDetail(
      checkRequestId: $checkRequestId
      fulfillmentPageInput: { perPage: 10, page: $page }
      fulFillmentFilter: $filter
    ) {
      fulfillmentsPageInfo {
        page
        perPage
        totalCount
      }
      checkRequestData {
        projectId {
          projectName
          exportType
        }
        isAllowed
        audit
        formName
        submissionDate
        vendorName
        vendorEmail
        description
        purchaseOrderNumber
        invoiceNumber
        dateRequired
        qualifiedExpense
        accountName
        apNumber
        episode
        dateRequired
        accountingSignOff
        accountName
        submissionStage
        paid
        paidDate
        company {
          companyName
          companyAddress
          file
        }
        accountSubtotals {
          accountCode
          details
          subtotal
          approvalMatrix
        }
        sets
        amount
        updateCount
        w9Upload
        proofOfPayment
        invoice
        contractUpload
        createdBy {
          userId {
            firstName
            lastName
          }
        }
        approvalStatus {
          status
          isFinal
          id
          approvalUserId {
            id
            role
            userId {
              firstName
              lastName
              signatureUpload
              id
            }
          }
        }
        givenApproval {
          mainApproverId
        }
        projectId {
          audit
        }
        inputFinance
        comments
        additionalNotes
        checkRequestInfo {
          locationRentalEnd
          locationRentalStart
          pickUpDate
          returnDate
          type
        }
      }
      submissionSignOff {
        firstName
        lastName
        id
        role
        signature
      }
      fulfillments {
        amount
        createdAt
        isDeleted
        status
        submissionId
        description
        createdBy {
          userId {
            firstName
            lastName
          }
        }
      }
      error
    }
  }
`
export const GET_SUBMISSION_NOTIFICATION = gql`
  query GetSubmissionNotification($projectId: String!) {
    getSubmissionNotification(projectId: $projectId) {
      error
      notificationList {
        notificationType
        notificationId
      }
    }
  }
`
export const GET_COMPANY_NOTIFICATION_COUNT = gql`
  query GetCompanyNotificationCount {
    getCompanyNotificationCount {
      unSeenCompanyCount
      unApprovedCompanyCount
    }
  }
`

export const GET_PROJECT_NOTIFICATION_COUNT = gql`
  query {
    getSubmissionNotificationCount {
      notifications {
        isApprover
        unApprovedSubmission
        unSeenSubmission
      }
      error
    }
  }
`

export const GET_APPROVAL_NAME = gql`
  query GetVendorName($searchData: String!, $companyId: String!) {
    getVendorName(
      vendorInput: { companyId: $companyId, searchData: $searchData }
    ) {
      error
      vendorData
    }
  }
`
export const GET_PURCHASE_ORDER_NUMBER = gql`
  query GetPurchaseOrderNumber($projectId: String!, $searchData: String!) {
    getPurchaseOrderNumber(
      purchaseOrderNumberInput: {
        projectId: $projectId
        searchData: $searchData
      }
    ) {
      purchaseOrderNumber {
        commitment
        email
        firstName
        lastName
        orderId
        description
        accountSubtotals {
          accountCode
          details
          subtotal
          approvalMatrix
        }
      }
      error
    }
  }
`
// export const GET_PURCHASE_ORDER_NUMBER = gql`
//   query GetPurchaseOrderNumber($projectId: String!, $searchData: String!) {
//     getPurchaseOrderNumber(
//       purchaseOrderNumberInput: {
//         projectId: $projectId
//         searchData: $searchData
//       }
//     ) {
//       error
//       purchaseOrderNumber
//     }
//   }
// `
export const GET_SUBMISSION_THUMB_NOTIFICATION = gql`
  query GetSubmissionNotificationList($projectId: String!) {
    getSubmissionNotificationList(projectId: $projectId) {
      error
      submissionData {
        formName
        amount
        id
      }
    }
  }
`

export const GET_ROUTING_NUMBER = gql`
  query checkRoutingNumber($bankName: String!, $routingNumber: String!) {
    checkRoutingNumber(
      bankInput: { bankName: $bankName, routingNumber: $routingNumber }
    ) {
      error
      message
      success
    }
  }
`
export const GET_COST_REPORT_CHART_DATA = gql`
  query GetProjectDetail($projectId: String!) {
    getProjectDetail(projectId: $projectId) {
      costReportGraph {
        date
        totalBudget
        totalCostToDate
        totalActualThisPeriod
        totalCommits
        totalEstimateToComplete
        totalEstimatedFinalCost
        totalPriorCost
        totalVariance
      }
      error
    }
  }
`
export const GET_PO_LIST_FINANCE = gql`
  query GetPOFinanceDetail($purchaseOrderId: String!) {
    getPurchaseOrderDetail(
      purchaseOrderId: $purchaseOrderId
      fulfillmentPageInput: { perPage: 1, page: 1 }
      POStoryPageInput: { perPage: 1, page: 1 }
    ) {
      error
      isMainReviewExist
      purchaseOrder {
        additionalNotes
        inputFinance
        id
        v1TransactionNumber
        reviewerStatus {
          isFinal
          inputFinance
          reviewerUserId {
            userId {
              id
            }
          }
        }
      }
    }
  }
`
export const GET_WT_LIST_FINANCE = gql`
  query GetWTFinanceDetail($wireTransferId: String!) {
    getWireTransferDetail(
      wireTransferId: $wireTransferId
      fulfillmentPageInput: { perPage: 1, page: 1 }
    ) {
      error
      isMainReviewExist
      wireTransferData {
        additionalNotes
        inputFinance
        paid
        id
        paidDate
        v1TransactionNumber
        reviewerStatus {
          isFinal
          inputFinance
          paid
          paidDate
          reviewerUserId {
            userId {
              id
            }
          }
        }
      }
    }
  }
`
export const GET_CCC_LIST_FINANCE = gql`
  query GetCCCFinanceDetail($creditCardChargeId: String!) {
    getCreditCardChargeDetail(
      creditCardChargeId: $creditCardChargeId
      fulfillmentPageInput: { perPage: 1, page: 1 }
    ) {
      error
      isMainReviewExist
      creditCardCharge {
        additionalNotes
        inputFinance
        id
        v1TransactionNumber
        reviewerStatus {
          isFinal
          inputFinance
          reviewerUserId {
            userId {
              id
            }
          }
        }
      }
    }
  }
`
export const GET_CR_LIST_FINANCE = gql`
  query GetCRFinanceDetail($checkRequestId: String!) {
    getCheckRequestDetail(
      checkRequestId: $checkRequestId
      fulfillmentPageInput: { perPage: 1, page: 1 }
    ) {
      error
      isMainReviewExist
      checkRequestData {
        additionalNotes
        inputFinance
        paid
        id
        paidDate
        v1TransactionNumber
        reviewerStatus {
          isFinal
          inputFinance
          paid
          paidDate
          reviewerUserId {
            userId {
              id
            }
          }
        }
      }
    }
  }
`
export const GET_SUBMISSION_FIELD = gql`
  query GetSUbmissionField($requestType: ReportBuilderType!) {
    getSubmissionField(requestType: $requestType) {
      error
      fieldName
    }
  }
`

export const GET_SUBMISSION_LIST = gql`
  query GetSubmissionList($projectId: ID!, $companyId: ID!) {
    getSubmissionList(
      pagination: { perPage: ${100}, page: ${1} }
      submissionInput: { companyId: $companyId, projectId: $projectId }
    ) {
      error
      submissionData
    }
  }
`

export const GET_CHART_OF_ACCOUNT_FILE = gql`
  query GetChartOfAccountFile($companyId: String!) {
    getChartOfAccounts(companyId: $companyId) {
      chartOfAccounts {
        file
      }
      error
    }
  }
`
export const GET_SUBMISSION_PDF = gql`
  query GetSubmissionPdf(
    $submissionId: String!
    $submissionType: RequestType!
  ) {
    getSubmissionPdf(
      submissionId: $submissionId
      submissionType: $submissionType
    ) {
      pdfUrl
      pdfName
    }
  }
`

export const GET_WALLET_BALANCE = gql`
  query GetWalletBalance($projectId: String!, $companyId: String!) {
    getWalletBalance(
      signetInput: { projectId: $projectId, companyId: $companyId }
    ) {
      walletInfo {
        depositBalance
        walletBalance
        withdrawBalance
      }
      error
    }
  }
`

export const GET_AP_INVOICE_DATA = gql`
query getApInvoiceList($projectId: ID!, $companyId: ID!,$page:Int!,$filter: SubmissionDataFilters,$submissionSort:SubmissionSort) {
  getApInvoiceList(
    apInvoiceInput: {
      projectId: $projectId
      companyId: $companyId
    }
    submissionFilter:$filter
    pagination: { perPage: ${10}, page: $page }
    submissionSort:$submissionSort
  ) {
    error
    isApprover
    pageInfo {
      page
      perPage
      totalCount
    }
    apInvoiceData {
      id
      accountSubtotals {
        accountCode
        details
        subtotal
      }
      approvalStatus {
        status
        id
        isFinal
        approvalUserId {
          userId {
            id
          }
        }
      }
      messageCount {
        id
        userId
        count
      }
      quickbooks {
        id
        syncToken
      }
      projectId {
        audit
      }
      amount
      apNumber
      comments
      submissionStage
      contractUpload
      createdAt
      description
      formName
      inputFinance
      invoice  
      vendorName
      reviewerStatus {
        inputFinance
        reviewerUserId {
          userId {
            id
          }
        }
      }
      submissionDate
      createdBy {
        userId {
          firstName
          lastName
          id
        }
      }
      audit
    }
  }
}

`
export const GET_AP_INVOICE_DETAIL = gql`
  query getApInvoiceDetail($apInvoiceId: String!, $page: Int!, $filter: JSON) {
    getApInvoiceDetail(
      apInvoiceId: $apInvoiceId
      fulfillmentPageInput: { perPage: 10, page: $page }
      fulFillmentFilter: $filter
    ) {
      apInvoiceData {
        audit
        accountSubtotals {
          accountCode
          details
          subtotal
          approvalMatrix
        }
        amount
        apNumber
        isAllowed
        approvalStatus {
          status
          id
          isFinal
          approvalUserId {
            userId {
              id
              firstName
              lastName
              signatureUpload
            }
            role
          }
        }
        companyId {
          companyAddress
          companyName
          file
        }
        comments
        contractUpload
        covidCost
        createdAt
        createdBy {
          userId {
            firstName
            lastName
          }
        }
        projectId {
          audit
          projectName
        }
        description
        episode
        formName
        id
        inputFinance
        invoice
        purchaseOrderNumber
        qualifiedExpense
        reviewerStatus {
          inputFinance
          paid
          paidDate
          reviewerUserId {
            userId {
              id
            }
          }
        }
        sets
        submissionDate
        vendorName
        w9Upload
        proofOfPayment
        paidDate
        paid
        submissionStage
      }
      error
      fulfillments {
        amount
        createdAt
        isDeleted
        description
        createdBy {
          userId {
            firstName
            lastName
          }
        }
        status
        submissionId
      }
      submissionSignOff {
        firstName
        lastName
        role
        signature
        id
      }
      fulfillmentsPageInfo {
        page
        perPage
        totalCount
      }
      isMainReviewExist
    }
  }
`
export const GET_AP_LIST_FINANCE = gql`
  query GetWTFinanceDetail($apInvoiceId: String!) {
    getApInvoiceDetail(
      apInvoiceId: $apInvoiceId
      fulfillmentPageInput: { perPage: 1, page: 1 }
    ) {
      error
      isMainReviewExist
      apInvoiceData {
        inputFinance
        additionalNotes
        paid
        id
        paidDate
        v1TransactionNumber
        reviewerStatus {
          isFinal
          inputFinance
          paid
          paidDate
          reviewerUserId {
            userId {
              id
            }
            id
          }
        }
      }
    }
  }
`
export const GET_CHAT_PDF = gql`
  query GetChatNotesPdf($chatNoteInput: GetMessageListInput!) {
    getChatNotesPdf(chatNoteInput: $chatNoteInput) {
      success
      pdfUrl
      error
    }
  }
`

export const GET_MANAGER_LIST = gql`
  query GetDepartmentApprovers {
    getDepartmentApprovers {
      userData {
        id
        role
        name
        email
        departmentName
      }
      error
    }
  }
`
